import React, {useEffect} from 'react';
import {useState} from 'react';
import Form from "react-bootstrap/Form";
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";


const PaymentStep = (props) => {


    const {formData, setFormData, handleChange, onSubmit, setValidSecondStep, setValidated, cost, sendEmail} = props;
    const [errorCC, setErrorCC] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const currentYear = new Date().getFullYear();
    const years = Array.from({length: 7}, (_, i) => currentYear + i);
    const months = Array.from({length: 12}, (_, i) => (i + 1).toString().padStart(2, '0'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (formData.authCode) {
            onSubmit();
        }
    }, [formData.authCode]);

    const paymentData = {
        "createTransactionRequest": {
            "merchantAuthentication": {
                "name": process.env.REACT_APP_AUTHORIZE_NET_LOGIN_ID,
                "transactionKey": process.env.REACT_APP_AUTHORIZE_NET_TRANSACTION_KEY
            },
            "transactionRequest": {
                "transactionType": "authCaptureTransaction",
                "amount": cost,
                "payment": {
                    "creditCard": {
                        "cardNumber": formData.cardNumber,
                        "expirationDate": formData.expirationYear + "-" + formData.expirationMonth,
                        "cardCode": formData.securityCode
                    }
                },
                "order": {

                    "description": "Dual Application"
                },

                "customer": {
                    "email": formData.email,

                },
                "billTo": {
                    "firstName": formData.firstName,
                    "lastName": formData.lastName,
                    "address": formData.billingAddress,
                    "city": formData.billingCity,
                    "state": formData.billingState,
                    "zip": formData.billingZip

                },
                "transactionSettings": {
                    "setting": {
                        "settingName": "testRequest",
                        "settingValue": "false"
                    }
                },


            }
        }
    };
    const chargePayment = async (event) => {
        setSubmitted(true);
        setErrorCC(false);


        const form = event.currentTarget.closest('form');
        if (form.checkValidity() === false) {
            setSubmitted(false);
            setValidated(true);
            return false;

        }


        fetch(process.env.REACT_APP_AUTHORIZE_NET_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData),
        })
            .then(response => {
                if (!response.ok) {
                    sendEmail('error', 'Payment Network response was not ok');
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const response = data;
                    if (response.transactionResponse && response.transactionResponse.responseCode === '1') {
                    // console.log('Payment Successful');
                    const authcode = response.transactionResponse.transId;
                    //clean credit card as 4===1
                    //get first char of card
                    const cardNumber = formData.cardNumber;
                    const firstChar = cardNumber.charAt(0);
                    //get last 4 digits

                    const lastChar = cardNumber.slice(-4);

                    //match firstchar to type of credit card
                    const cardType = (firstChar === '4') ? 'Visa' : (firstChar === '5') ? 'MasterCard' : (firstChar === '3') ? 'American Express' : (firstChar === '6') ? 'Discover' : 'Credit Card';
                    const cleanedCard = cardType + '************' + lastChar;
                    setFormData({
                        ...formData,
                        authCode: authcode,
                        cleanedCardNumber: cleanedCard
                    });



                } else {
                    setErrorCC(true);
                    setSubmitted(false);
                    // console.log('Payment Failed: ' + response.transactionResponse.errors[0].errorText);
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                sendEmail('error', 'There was a problem with the payment fetch operation:'+  error);
            });


    };

    const handleSameAddress = (event) => {
        if (event.target.checked) {
            setFormData({
                ...formData,
                billingAddress: formData.address,
                billingCity: formData.city,
                billingState: formData.state,
                billingZip: formData.zip
            });
        }

    }

    const handleGoBack = () => {
        setValidSecondStep(false);

    }

    return (
        <>

            <Row className="mb-3 text-start">
                <h3> Verify your selected courses</h3>


                {(formData.courses ?? []).length > 0 && (
                    <>

                        {(formData.courses ?? []).map((item, index) => (
                            <div key={index}>
                                {item.name} - (${item.amount})
                            </div>
                        ))}
                    </>
                )}
                <Form.Group as={Col} md="3">

                    <button onClick={handleGoBack} type="button" className="mt-4 btn btn-primary"> Click here to make
                        changes if needed.
                    </button>
                </Form.Group>
            </Row>
            <Row className="mb-1 mt-4 text-start">
                <div className="fw-bold fs-5 mb-3">Payment Total: ${cost}</div>
            </Row>
            <Row className="mb-3 text-start">

                <Form.Group as={Col} md="12" controlId="cardName">
                    <Form.Label>Name on Card</Form.Label>
                    <Form.Control type="text" placeholder="Card Name" required value={formData.cardName || ""} onChange={handleChange}/>

                </Form.Group>
                <Form.Group as={Col} md="12" controlId="cardNumber" className="mt-2">
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control type="text"
                                  placeholder="Card Number" required
                                  value={formData.cardNumber || ""}
                                  maxLength="16" minLength="15" onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid card number.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="expirationMonth">
                    <Form.Label>Month</Form.Label>
                    <Form.Select required defaultValue={formData.expirationMonth || ""} onChange={handleChange}
                    >
                        <option value="">Select</option>
                        {months.map(month => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </Form.Select> <Form.Control.Feedback type="invalid">
                    Please provide a valid expiration date.
                </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="expirationYear">
                    <Form.Label>Year</Form.Label>
                    <Form.Select required defaultValue={formData.expirationYear || ""}

                                 onChange={handleChange}
                    >
                        <option value="">Select</option>
                        {years.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid expiration date.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="securityCode">
                    <Form.Label>Security Code</Form.Label>
                    <Form.Control type="tel" placeholder="Security Code" required value={formData.securityCode || ""}
                                  maxLength="4" minLength="3" onChange={handleChange} pattern="[0-9]*"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid security code.
                    </Form.Control.Feedback>
                </Form.Group>

            </Row>
            <Row className="mb-3 text-start">
                <Form.Group as={Col} md="4" controlId="sameAddress">
                    <Form.Check type="checkbox" label="Billing address same as above" onClick={handleSameAddress}/>
                </Form.Group>
            </Row>
            <Row className="mb-3 text-start">
                <Form.Group as={Col} md="12" controlId="billingAddress">
                    <Form.Label>Billing Address</Form.Label>
                    <Form.Control type="text" placeholder="Address" required onChange={handleChange}
                                  value={formData.billingAddress}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid address.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="billingCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="City" required onChange={handleChange}
                                  value={formData.billingCity}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid city.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="billingState">
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" placeholder="State" required onChange={handleChange}
                                  value={formData.billingState}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid state.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="billingZip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control type="text" placeholder="Zip" required pattern="[0-9]*" onChange={handleChange}
                                  value={formData.billingZip}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid zip.
                    </Form.Control.Feedback>
                </Form.Group>

            </Row>
            {(() => submitted ? <Button disabled>Processing...</Button> : <Button onClick={chargePayment}>Pay Now</Button>)()}

            {errorCC && (
                <div className="alert alert-danger mt-3 mb-3">Your card was declined. Your registration is not complete
                    until your payment is processed. Please try a different card.
                </div>

            )}

        </>
    );
}


export default PaymentStep;
