import React from 'react';

import Row from "react-bootstrap/Row";


const ThankYou= (props) => {

    const {formData} = props;
    return (
        <>

            <Row className="mb-3 text-start">
                <h3 className="text-center">  Registration Complete</h3>
               <div> {formData.firstName} {formData.lastName},</div>
               <div>
                   You have successfully registered in the Sara Schenirer Dual Credit Program.<br />
                   Please check your email for a welcome letter and an automated receipt.<br />
                   Once your payment has been successfully processed, you will receive an email confirmation that your registration is complete.

   </div>
            </Row>
            </>
            );
}



            export default ThankYou;
