import 'bootstrap/dist/css/bootstrap.min.css';


import './App.css';

import Application from "./application/form/Application";
import Header from "./application/Header";
import Footer from "./application/Footer";

function App() {
  return (
      <>
      <Header />
    <div className="App container mt4">
        <Application />

    </div>
          <Footer />
      </>
  );
}

export default App;
//https://docs.google.com/spreadsheets/d/e/2PACX-1vQFxIikKda3T2DLUCC4aHbqQhrwKoYLOUTC-ht8e4BJWX9hIM2pMHeujoAzIx_T9GQR7bDPLjDi2S6V/pub?gid=0&single=true&output=csv