import React from 'react';

import Row from 'react-bootstrap/Row';

import Button from "react-bootstrap/Button";
import CourseListing from "./CourseListing";

const SecondStep= (props) => {
    const {formData, setFormData,  SubmitSecondStep, validSecondStep, cost, setCost} = props;

    return (
        <><Row className="mb-1 mt-5 text-start"><h3>Course Options for {formData.school && (formData.school['value'])} - Grade {formData.grade}</h3>
            <div><i>*Each course costs $50 per credit.</i>

            </div>
        </Row>
            <Row className="mb-3">
                <CourseListing formData={formData} setFormData={setFormData} cost={cost} setCost={setCost} validSecondStep={validSecondStep} />


            </Row>
            {!validSecondStep && cost > 0 &&  (

            <Button type="button"  onClick={SubmitSecondStep}>Next></Button>
            )}

        </>
    );
}
export default SecondStep;