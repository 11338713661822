

const Footer = () => {

    const d = new Date();

    return (
        <>
     <footer className=" d-flex flex-wrap justify-content-between align-items-center py-3 mt-5 border-top">
<div>©    {  d.getFullYear()}     Sara Schenirer</div>

     </footer>
        </>
    );
};

export default Footer;