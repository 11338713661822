import * as React from "react";
import { useRef, useState} from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import useGoogleSheets from 'use-google-sheets';


const SchoolListing = ({ formData, setFormData }) => {

    const [singleSelections, setSingleSelections] = useState( [ formData.school]||[]);

    const typeaheadRef = useRef();

    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const sheetId = "1sbMo86wP5E9VWnlIDbmzrefPvPVv7A8aUV40Fx1FYwI";

        const { data } = useGoogleSheets({
        apiKey: apiKey,
        sheetId: sheetId,
        sheetsOptions: [{ id: 'Schools' }],
    });


    const originalData = data[0];
    const options = originalData?.data.map(item => ({
        id: item.CEEB,
        value: item.School,
        college: item.CollegeAttending
    }));


const validateSelected = () => {

    if(singleSelections.length === 0){
        setSingleSelections([''])
    }
    setFormData({
        ...formData,
        school: singleSelections[0],
    });
}
    return (
        <>

        <Typeahead
            id="School"
            minLength= {2}
            ref={typeaheadRef}
            labelKey="value"
            onChange={setSingleSelections}
            options={options|| []}
            placeholder="Start typing..."
            selected={singleSelections }
            clearButton
            // value={formData.school && formData.school['value']  || "" }
            required
            onBlur={validateSelected}
            inputProps={{ required: true }}

        />

        </>
    );
}
export default SchoolListing;