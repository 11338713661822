
import React, {useEffect,   useState} from "react";

import useGoogleSheets from 'use-google-sheets';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


const CourseListing = ({ formData, setFormData, cost, setCost,  }) => {
const [message, setMessage] = useState('');
    const [options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const sheetId = "1sbMo86wP5E9VWnlIDbmzrefPvPVv7A8aUV40Fx1FYwI";

    const { data } = useGoogleSheets({
        apiKey: apiKey,
        sheetId: sheetId,
        sheetsOptions: [{ id: 'PublishedCourses' }],
    });


    useEffect(() => {


    const originalData = data[0];
setOptions( originalData?.data.map(item => ({
        id: item.CourseID,
        school: item.School,
        CEEB: item.CEEB,
        courseName : item.CourseName,
        semester: item.Semester,
        grade: item.Grade,
    amount: item.Amount

    }))) ;

    }, [data ]);

    const filterOptionsBySchoolGrade = (options, schoolId, grade) => {
        // Check if school or grade is blank (falsy value)
        if (!schoolId || !grade) {
            return []; // Return empty array if blank
        }

        if (!options) return options;
        return options.filter(
            item => item.CEEB === schoolId && item.grade.includes(grade)
        );
    };

    useEffect(() => {

        // Filter options if necessary
        if (formData.school?.id && formData.grade && options) {
            const newFilteredOptions = filterOptionsBySchoolGrade(options, formData.school.id, formData.grade);
            setFilteredOptions(newFilteredOptions);

                window.scrollTo(0, document.body.scrollHeight);


        } else {
            setFilteredOptions([]);
        }


    }, [ formData.school, formData.grade, options]);

    useEffect(() => {
        //loop through each courses and add courses.amount
        let courseTotal = 0 ;
        formData.courses.map((course) => {
            courseTotal += Number(course.amount);

        });
        setCost(courseTotal); // Assuming cost needs to be updated on every change
    }, [formData.courses]);



    const handleCheck = (event) => {
        const { value, checked } = event.target;
        const label = event.target.getAttribute('data-label');
        const amount = event.target.getAttribute('data-amount').replace('$', '');

        setFormData(prevData => {
            let updatedCourses = [...prevData.courses];

            if (checked) {
                if (!updatedCourses.some(course => course.id === value)) {
                    updatedCourses.push({ id: value, name: label, amount: amount });
                }
            } else {
                updatedCourses = updatedCourses.filter(course => course.id !== value);
            }

            if (formData.school.college === '7- College of Mt St Vincent' &&  updatedCourses.length > 8) {
                event.target.setCustomValidity('You can choose up to 8 courses (24 credits).');
                setMessage('You can choose up to 8 courses (24 credits).');

            } else {
                event.target.setCustomValidity('');
                setMessage('');
            }

            return {
                ...prevData,
                courses: updatedCourses,
            };
        });
    }

    const isCourseChecked = (courseId) => {
        return formData.courses.some(course => course.id === courseId);
    };



    return (
        <>
            <Row className="mb-3 text-start">

                {filteredOptions && filteredOptions.length>0 ? (
                    <>


            <Form.Group as={Col} md="12" controlId="courses">
                <Form.Label></Form.Label>

                <div key={`inline-radio`} className="mb-3">

            {(filteredOptions|| []).map((item, index) => {
        return (
            <Form.Check
                type="checkbox"
                key={index}
                id={`course${index}`}
                value={item.id}

                label={`${item.courseName} - ${item.semester} (${item.amount})`}
                data-label={`${item.courseName} - ${item.semester}`}
                data-amount={item.amount}
                checked={isCourseChecked(item.id)}
                onChange={handleCheck}
            />
        );
})}
                </div>
            </Form.Group>

                <div className="mt-4 fw-bold">Total Cost: ${cost}</div>
                    </>
            ) : (
                    <div className="mt-4">We don't have any courses for this school and grade combination.</div>
                    )}
                {message && <div className="alert alert-danger mt-4 mb-4 ">{message}</div>}
            </Row>
        </>
    );
}
export default CourseListing;